import { SiteConfig } from '../types';

export const siteConfig: SiteConfig = {
  name: 'sprunked incredibox',
  slogan: 'Unleash Your Inner Musician',
  domain: 'https://www.sprunked-incredibox.online',
  icon: 'favicon.ico',
  appleIcon: 'apple-touch-icon.png',
  theme: {
    headFont: 'Arial, sans-serif',
    primaryColor: '#007bff',
    fontColor: '#333333',
    backgroundColor: '#ffffff',
  },
  gaId: 'G-4LJ1C01DQK',
  plausible: '',
  navbarItems: [
    {
      title: 'home',
      href: '/',
    },
    {
      title: 'guide',
      href: '/guide',
    },
    {
      title: 'information',
      href: '/t',
    },
  ],
  gameType: 'iframe',
  gameIframeUrl: 'https://c.sprunked.org/game/sprunked-v2/index.html',
  gameIframeUrlv2: 'https://s.sprunkiincredibox.com/game/sprunki/index.html',
  gameDownloadBg: '',
  gameDownloadUrl: '',
  isShowFAQs: true,
  isShowVideo: true,
  isShowGuide: true,
  videos: [
    {
      title: 'I Played THE SPRUNKED MOD',
      url: 'https://www.youtube.com/watch?v=7i52lwBdQU0',
    },
    {
      title: 'Sprunki "BUT BAD" is actually AMAZING!! | Incredibox Sprunked',
      url: 'https://www.youtube.com/watch?v=utaIKEDcoaU',
    },
    {
      title: 'The NEW SPRUNKI Is Terrifying.. (Sprunked)',
      url: 'https://www.youtube.com/watch?v=ku4vkayhhog',
    },
  ],
  isShowComments: true,
  isShowTweets: false,
  useRealTweets: true,
  tweets: [''],
  comments: [
    {
      author: 'Fariz',
      role: 'player',
      content: 'For trying the game this game the sound of the spubd its awesome',
      avatar: 'users/1.png',
    },
    {
      author: 'Lex Fridman player',
      role: 'player',
      content: "I'm a big fan of the original Sprunki, and I'm not disappointed with the Retake. The new features are great, and the gameplay is still as fun as ever.",
      avatar: 'users/2.png',
    },
    {
      author: 'Packy McCormick',
      role: 'player',
      content: "This game is so charming! The art style is beautiful, and the story is heartwarming. I love the characters and the world they live in.",
      avatar: 'users/3.png',
    },
    {
      author: 'Lorenz S.',
      role: 'player',
      content: "I'm having a blast playing Sprunki Retake! The controls are responsive, and the music is great. The levels are a nice mix of easy and challenging.",
      avatar: 'users/4.png',
    },
    {
      author: 'Ben Stephens',
      role: 'player',
      content: "This game is pure joy! The level design is creative, and the characters are so cute. I highly recommend it to anyone looking for a fun and challenging platformer.",
      avatar: 'users/5.png',
    },
    {
      author: 'Mike Taylor',
      role: 'player',
      content: "I never played the original Sprunki, but I'm really enjoying the Retake. The controls are easy to learn, and the levels are challenging enough to keep me coming back for more.",
      avatar: 'users/6.png',
    }
  ],
  isShowRecommendation: true,
  friendLinks: [
    {
      title: 'incredibox-game',
      url: 'https://incredibox-game.io/sprunked',
    }
  ],
};
